<template>
    <div >
        <el-row>

            <el-button
            style="float: left; margin: 7px"
            size="small"
            type="primary"
            icon="el-icon-plus"
            @click="handleAdd()"
            >
            新增排程
            </el-button>
          
            <el-select style="float: right;" v-model="serviceStation" :placeholder="$t('ChargingStationS')+'('+$t('All')+')'"
            clearable size="small" class="filter-item-mid" width="140px">
            <!--
            <el-option v-for="item in stationOption" :key="item.stationId"
                     :label="item.stationName + ' (id:' + item.stationId + ')'" :value="item.stationId" />-->
            <el-option v-for="item in stationOption" :key="item.stationId"
                     :label="item.stationName" :value="item.stationId" />
            </el-select>

       </el-row>
    <el-divider content-position="right"></el-divider>
     <el-row>
           <el-table
             :data="schedule_Table" style="width: 100%;"
             :cell-style="{padding: '0', height: '70px'}"
              @sort-change="tableSortChange"
           >
           <el-table-column  align="center" prop="stationName" label="充電站"  width="220" :show-overflow-tooltip="true" sortable/>
           <el-table-column  align="center" prop="pointName" label="充電樁"  width="220" :show-overflow-tooltip="true" sortable/>
           <el-table-column  align="center" prop="connectorName" label="充電槍"  width="80" :show-overflow-tooltip="true" />
           <el-table-column  align="center" prop="weekDay" label="星期"  width="300" :show-overflow-tooltip="true" />
           <el-table-column  align="center"   label="開始時間"  width="150" :show-overflow-tooltip="true" >
           <template slot-scope="scope">                     
            <span> {{changeType_start(scope.row.startHour)}}</span>
        </template>
      </el-table-column>
           <el-table-column  align="center"  label="結束時間"  width="150" :show-overflow-tooltip="true" >
           <template slot-scope="scope">                     
            <span> {{changeType_end(scope.row.endHour)}}</span>
        </template>
      </el-table-column>
           <el-table-column  align="center" prop="login" label="登入帳號"  width="150" :show-overflow-tooltip="true" />
           <el-table-column  align="center"  label="開啟狀態"  width="120" :show-overflow-tooltip="true" >
            <template slot-scope="scope">
            <el-tag v-if="scope.row.enable=='1'"  type="success"> {{$t('Yes')}} </el-tag>
            <el-tag v-else type="danger"> {{$t('No')}} </el-tag>
              </template>
           </el-table-column>

           <el-table-column v-if="hidden==true" align="center" prop="id" label="id"  :show-overflow-tooltip="true" />
           <el-table-column  align="center" label="操作" width="180" >
             <template slot-scope="scope">
                 <el-button type="success" size="mini" icon="el-icon-setting" @click="showScheduleSetting(scope.row)"/>             
                 <el-button  type="danger" size="mini" icon="el-icon-delete"  @click="deleteSchedule(scope.row)"/>
             </template>
           </el-table-column>
           </el-table>
       </el-row>




        <!-- 編輯 -->
        <el-dialog width="900px"  :visible.sync="dialogSettingVisible" @close="dialogOnClose()" style="padding-top: 10px;">
        <span class="filter-label" style="font: 2em sans-serif;"> {{this.isModify==true? "編輯":"新增排程" }}</span>
        <br>

        <el-divider content-position="left">設定</el-divider>
<el-row :gutter="20">
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 10px;">
            <span class="data-label">開啟/關閉: </span>
            <el-switch
                    v-model="onOffSchedule"
                    active-color="#13ce66"
                    inactive-color="#bfbbbb"
                    style="margin-bottom: 10px">
                </el-switch>
         </el-col>
</el-row>        

        <el-divider content-position="left">站/樁/槍選擇</el-divider>
        <el-row :gutter="20">
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 10px;">
            <span class="data-label">充電站: </span>
           <el-select v-model="serviceStation" placeholder="選擇充電站(All)"  size="small" class="filter-item" clearable>
            <el-option v-for="item in stationOption" :key="item.stationId"
            :label="item.stationName " :value="item.stationId"   />
           </el-select>
          </el-col>

          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 10px;">
            <span class="data-label">充電樁: </span>
            <el-select v-model="chargePointOption" placeholder="選擇充電樁(All)"  size="small" class="filter-item" clearable>
            <el-option v-for="item in  chargeOption" :key="item.pointId"
            :label="item.pointName " :value="item.pointId" />
           </el-select>
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 10px;">
            <span class="data-label">充電槍: </span>
            <el-select v-model="chargeConnectorOption" placeholder="選擇充電槍(All)"  size="small" class="filter-item" clearable>
             <el-option v-for="item in connectorOption"
                :key="item.connectorId"
                :label="item.connectorName"
                :value="item.connectorId" />
           </el-select>
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 10px;">
            <span class="data-label">登入帳號: </span>
            <span class="data-label">{{this.inputAccount}} </span>
           <!-- <el-input v-model="inputAccount" placeholder="登入帳號" style="width: 180px;" size="small" disabled></el-input>-->
        </el-col>
        </el-row>



        <el-divider content-position="left">時間選擇</el-divider>

        <el-row :gutter="20">
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 10px;">
            <span class="data-label">快選: </span>
            <el-radio-group v-model="radioCheck" size="small">
             <el-radio  label="1" border>全選</el-radio>
             <el-radio  label="2" border>全不選</el-radio>
            </el-radio-group>
         </el-col>

         <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 20px;">
            <span class="data-label">星期: </span>
            <el-checkbox v-model="monday" >星期一</el-checkbox>
            <el-checkbox v-model="tuesday" >星期二</el-checkbox>
            <el-checkbox v-model="wednesday" >星期三</el-checkbox>
            <el-checkbox v-model="thursday" >星期四</el-checkbox>
            <el-checkbox v-model="friday" >星期五</el-checkbox>
            <el-checkbox v-model="saturday" >星期六</el-checkbox>
            <el-checkbox v-model="sunday" >星期日</el-checkbox>
         </el-col>

         <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 20px;">
            <span class="data-label">開始結束時間: </span>
            <el-time-select
    placeholder="開始時段"
    v-model="startTime"

    :picker-options="{
      start: '00:00',
      step: '00:60',
      end: '24:00'
    }">
  </el-time-select>
 <span class="filter-label" style="font: 2em sans-serif;">  </span>
  <el-time-select
    placeholder="結束時段"

    v-model="endTime"
    :picker-options="{
      start: '00:00',
      step: '00:60',
      end: '24:00',
      minTime: startTime
    }">
  </el-time-select>
</el-col>
</el-row>
<br>

<br>
       <div style="text-align: center">
        <el-button size="small" @click="dialogSettingVisible = false">
          取消
        </el-button>
        <el-button size="small" type="primary" @click="seScheduleRule()">
          確定
        </el-button>
      </div>

    </el-dialog>
    </div>


    </template>

       <script>
       //import Pagination from '@/components/Pagination'
       import { mixins } from '@/views/common/mixins.js'
       import {refreshToken,stopRefreshToken} from "@/utils/auth";

       export default {
           name: 'Station',
           components: {
               //Pagination
           },
           mixins: [mixins],
           data() {
               return {
               serviceId: this.$store.state.currentAccount.serviceInfo.id,
               schedule_Table:[],
               stationOption:[],
               chargeOption:[],
               connectorOption:[],
               serviceStation:undefined,
               chargePointOption:undefined,
               chargeConnectorOption:undefined,
               dialogSettingVisible:false,
               checkAll: false,
               clearAll: false,
               startTime: undefined,
               endTime: undefined,
               radioCheck: '',
               monday:false,
               tuesday:false,
               wednesday:false,
               thursday:false,
               friday:false,
               saturday:false,
               sunday:false,
               inputAccount:undefined,
               hidden:false,
               scheduleData: undefined, 
               isModify: false,
               id:undefined,
               onOffSchedule:false,
               };
           },
         watch: {

            serviceStation(val){

                if(!this.isModify) {
                  this.chargePointOption=null
                  this.chargeConnectorOption=null
                }
               // console.log("serviceStation" + val);
                if( this.serviceStation ) {                 
                  this.targetUrl =  "/api/v1/schecharge/pointidListByserviceIdandstationId?serviceId="+this.serviceId+"&stationId="+val
                 // console.log("targetUrl" + this.targetUrl);
                  this.axios.get(this.targetUrl).then(res => {
                 // console.log("pointidListByserviceIdandstationId res.data" + res.data);
                     this.chargeOption=res.data
                  })
                }
               // this.getScheduleList()  //2024 1204 bowa del, 因為在點選充電站時, 不要去更新到後面的背景列表
            },
            monday(value)
            {
              // console.log("monday value" + value);
            },


            chargePointOption(value) {
                if(!this.isModify) {
                  this.chargeConnectorOption=null
                }
                //console.log("chargePointOption" + value);
                if( this.serviceStation && this.chargePointOption ) { 
                  this.targetUrl =  "/api/v1/schecharge/connectoridListBy3Id?serviceId="+this.serviceId+"&stationId="+this.serviceStation+"&chargepointId="+value
                //  console.log("targetUrl" + this.targetUrl);
                  this.axios.get(this.targetUrl).then(res => {
                 // console.log("connectoridListBy3Id res.data" + res.data);
                  this.connectorOption=res.data
                  })
                }

            },
            radioCheck(value){
               // console.log("radioCheck:" + value);
                if(value==1)
                {
                 // console.log("radioCheck:" + "if 1" );
                    this.monday=true,
                    this.tuesday=true,
                    this.wednesday=true,
                    this.thursday=true,
                    this.friday=true,
                    this.saturday=true,
                    this.sunday=true
                }
                else
                 {
                //  console.log("radioCheck:" + "else 2" );
                    this.monday=false,
                    this.tuesday=false,
                    this.wednesday=false,
                    this.thursday=false,
                    this.friday=false,
                    this.saturday=false,
                    this.sunday=false
                 }
            },

            scheduleData(val) {
               if (val) {
                     this.isModify = true
                     this.serviceStation = val.stationId.toString()
                     this.chargePointOption = val.pointId.toString()
                     this.chargeConnectorOption = val.connectorId.toString()
                     this.inputAccount          = val.login
                     this.monday    =  val.week1=="1" ? true:false
                     this.tuesday   =  val.week2=="1" ? true:false
                     this.wednesday =  val.week3=="1" ? true:false
                     this.thursday  =  val.week4=="1" ? true:false
                     this.friday    =  val.week5=="1" ? true:false
                     this.saturday  =  val.week6=="1" ? true:false
                     this.sunday    =  val.week7=="1" ? true:false
                     this.startTime =  val.startHour+":00"
                     this.endTime   =  val.endHour+":00"
                     this.id        =  val.id
                     this.onOffSchedule = val.enable=="1" ? true:false  //bowa add 0719
               }
            },            

        },
         created() {
            this.loginAccount=this.$store.state.currentAccount.name
            this.inputAccount =this.$store.state.currentAccount.name
            this.getScheduleList()
            this.getStationByServiceId()
            //refreshToken()
         },
         beforeDestroy() {
            //stopRefreshToken()
         },


         mounted() {

         },
         methods: {

          handleAdd(){
            this.dialogSettingVisible = true
          },

          changeType_start(val)
          {
            var temp = val+":00"
           // console.log("temp=",temp)
           return temp
          },
          changeType_end(val)
          {
             var temp2 = val+":00"
           //  console.log("temp2=",temp2)
           return temp2
          },
          showScheduleSetting(val) {
            this.dialogSettingVisible = true
            //console.log("shoScheduleSetting(val): val=",val)
            this.scheduleData      = val;
            this.radioCheck='' //bowa add 1203
          },

          getStationByServiceId()
          {
            this.targetUrl =  "/api/v1/schecharge/stationidListByserviceId?serviceId="+this.serviceId
           // console.log("targetUrl" + this.targetUrl);
            this.axios.get(this.targetUrl).then(res => {
               // console.log("getStationByServiceId res.data" + res.data);
               this.stationOption=res.data

            })
          },

           getScheduleList(){
            this.targetUrl =  "/api/v1/schecharge/getschecharge?serviceId="+this.serviceId
          // if( this.serviceStation ) { this.targetUrl = this.targetUrl + "&stationId=" + this.serviceStation }   //2024 1204 bowa del, 因為更新總列表, 不要加入stationId, 這樣會導致數量出差錯
           // console.log("targetUrl" + this.targetUrl);
            this.axios.get(this.targetUrl).then(res => {
             //   console.log("res.data" + res.data);
               this.schedule_Table=''
               this.schedule_Table=res.data.content

            })

           },
           deleteSchedule(val){
           // console.log("this.userId :" + val.userId);

           this.targetUrl = "/api/v1/schecharge/delScheChargedata?Id="+ val.id
          // console.log("this.targetUrl :" + this.targetUrl);
           this.axios.get(this.targetUrl).then(res => {
          // console.log("deleteAlertUser :" + res.data.status);
           if(res.data.status=='ok')
           {
            this.getScheduleList();
             return this.$message({ message: '刪除成功', type: 'warning' })
           }
           else
              return this.$message({ message: '刪除失敗', type: 'warning' })

          })
           },

           seScheduleRule(){


            if(this.serviceStation==null || this.serviceStation==undefined)
            {
                return this.$message({ message: '請選擇充電站', type: 'warning' })
            }

            if(this.monday==false && this.tuesday==false && this.wednesday==false && this.thursday==false &&  this.friday==false && this.saturday==false &&  this.sunday==false)
            {
                return this.$message({ message: '請選擇排程星期時間', type: 'warning' })
            }

            if(this.startTime==null || this.startTime==undefined)
            {
                return this.$message({ message: '請選擇排程開始時間', type: 'warning' })
            }

            if(this.endTime==null || this.endTime==undefined)
            {
                return this.$message({ message: '請選擇排程結束時間', type: 'warning' })
            }

            this.targetUrl =  "/api/v1/schecharge/addScheChargedata?serviceId="+this.serviceId+"&stationId="+this.serviceStation


  
            if(this.chargePointOption==null || this.chargePointOption==undefined) //樁沒選擇
            {
                    this.targetUrl += "&chargepointId=&connectorId=0"
            }
            else //樁有選擇
            {
                if(this.chargeConnectorOption==null || this.chargeConnectorOption==undefined) //槍沒選擇
                {
                    return this.$message({ message: '請選擇充電槍', type: 'warning' })
                }
                else  //樁有選擇, 槍有選擇
                {
                    this.targetUrl += "&chargepointId="+this.chargePointOption+"&connectorId="+this.chargeConnectorOption
                }
            }

            if(this.monday ==true)    this.targetUrl +="&week1=1"
            else                      this.targetUrl +="&week1=0"

            if(this.tuesday ==true)   this.targetUrl +="&week2=1"
            else                      this.targetUrl +="&week2=0"

            if(this.wednesday ==true) this.targetUrl +="&week3=1"
            else                      this.targetUrl +="&week3=0"

            if(this.thursday ==true)  this.targetUrl +="&week4=1"
            else                      this.targetUrl +="&week4=0"

            if(this.friday ==true)    this.targetUrl +="&week5=1"
            else                      this.targetUrl +="&week5=0"

            if(this.saturday ==true)  this.targetUrl +="&week6=1"
            else                      this.targetUrl +="&week6=0"

            if(this.sunday ==true)    this.targetUrl +="&week7=1"
            else                      this.targetUrl +="&week7=0"

             if(this.onOffSchedule ==true)    this.targetUrl +="&enable=1"
            else                      this.targetUrl +="&enable=0"

         

           this.targetUrl +="&startHour="+this.startTime.substring(0,2)+ "&endHour="+this.endTime.substring(0,2)+"&loginuser="+this.inputAccount
           if( this.isModify && this.id ) this.targetUrl += "&Id=" + this.id

            //console.log("targetUrl" + this.targetUrl);

            this.axios.get(this.targetUrl).then(res => {
             // console.log("res.data.content:" + res.data);
             // console.log("res.data.content:" + res.data.status);


              //return this.$message({ message: '更新成功', type: 'warning' })
            if(res.data.status=='ok')
            {
              this.getScheduleList() //重新抓一次
              this.radioCheck=2
              this.dialogSettingVisible=false
             return this.$message({ message: '更新成功', type: 'warning' })

            }
           else
              return this.$message({ message: res.data.status, type: 'warning' })

          })

           },

           dialogOnClose() {
            this.userAccount = undefined
            this.radioCheck  = 2
            this.monday      = false
            this.tuesday     = false
            this.wednesday   = false
            this.thursday    = false
            this.friday      = false
            this.saturday    = false
            this.sunday      = false
            this.startTime   = undefined
            this.endTime     = undefined

            /* 關閉視窗則編輯相關資料清除 */
            this.inputAccount      = this.$store.state.currentAccount.name
            this.id                = undefined
            this.scheduleData      = undefined
            this.isModify          = false
            this.serviceStation    = undefined
            this.chargePointOption = null
            this.chargeConnectorOption = null
            /* 關閉視窗則編輯相關資料清除 */

            this.$emit('close') // 關閉視窗

    },

         }
       }

</script>

<style lang="scss" scoped>

.checkbox {
zoom:100%;
}
.alert {
zoom:100%;
}


.data-label {
  width: 120px; display: inline-block;
}
.data-amount{
  font-size: 1.2em;
  font-weight: bolder;
  color: #409eff;
}
</style>
